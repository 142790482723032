<template>
    <div id="blog">
        <div class="main-page-wrapper">
            <!-- ===================================================
				Loading Transition
			==================================================== -->
            <section>
                <div id="preloader">
					<div id="ctn-preloader" class="ctn-preloader">
						<div class="animation-preloader">
							<div class="text-center">
								<img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
							</div>
							<!-- <div class="spinner"></div> -->
							<div class="txt-loading">
								<span data-text-preloader="i" class="letters-loading">
									i
								</span>
								<span data-text-preloader="n" class="letters-loading">
									n
								</span>
								<span data-text-preloader="g" class="letters-loading-green">
									g
								</span>
								<span data-text-preloader="o" class="letters-loading-green">
									o
								</span>
								<span data-text-preloader="m" class="letters-loading">
									m
								</span>
								<span data-text-preloader="u" class="letters-loading">
									u
								</span>
							</div>
						</div>
					</div>
				</div>
            </section>


            <!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
            <SiteHeader></SiteHeader>

            <!--
			=====================================================
				Feature Blog One
			=====================================================
			-->
            <div class="blog-page-bg pt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 feature-blog-one width-lg" v-if="shownArray.length > 0">
                            <div class="post-meta" v-for="blog in shownArray" :key="blog.slug">
                                <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }" class="title">
                                    <img :src="imageLink(blog.xlimage)" alt="" class="image-meta">
                                    </router-link>
                                <div class="tag text-dark">{{ shortFormatDate(blog.date) }}</div>
                                <h3>
                                    <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }" class="title">{{
                                    blog.title }}</router-link>
                                    </h3>
                                    <p class="text-dark">{{ strippedHtml(blog.body) }}...</p>
                                <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }"
                                    class="read-more d-flex justify-content-between align-items-center">
                                    <span>Read More</span>
                                    <i class="flaticon-right-arrow"></i>
                                </router-link>
                            </div> <!-- /.post-meta -->

                            <div class="page-pagination-one pt-15" v-if="this.currentPage <= this.numPages">
                                <div class="col-xl-12">
                                    <div class="next-prev-btn d-flex align-items-center justify-content-between">
                                        <div class="prev-btn">
                                            <router-link :to="{ name: 'blogpage', params: { page: this.currentPage - 1}}" rel=”prev” class="btn-link btn-link--prev text-dark" v-if="this.currentPage > 1">
                                                <i
                                                    class="fa fa-arrow-left"></i>prev
                                            </router-link>
                                        </div>
                                        <div class="prev-btn">
                                            <router-link :to="{ name: 'blogpage', params: { page: this.currentPage + 1}}" rel="next" class="btn-link btn-link--next text-dark" v-if="this.currentPage < this.numPages">
                                                next<i
                                                    class="fa fa-arrow-right"></i>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <BlogSidebar></BlogSidebar>
                    </div>
                </div>
            </div> <!-- /.feature-blog-one -->

            <!--
			=====================================================
				Footer
			=====================================================
			-->
            <SiteFooter></SiteFooter>

            <!-- Modal Contact Form One -->
			<div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
				aria-labelledby="contactModalTitle" aria-hidden="true">
				<ContactUs @clicked="closeModal"></ContactUs>
			</div>

            <!-- Scroll Top Button -->
            <button class="scroll-top">
                <i class="fa fa-angle-up" aria-hidden="true"></i>
            </button>

        </div>
    </div>
</template>
<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import ContactUs from "@/components/ContactUs";
import BlogSidebar from "./BlogSidebar"
import { mapActions, mapGetters } from "vuex";
import formateDateMixin from "@/mixins/formatDate";

export default {
    name: "blog",
    metaInfo() {
        return {
            title: 'Empower Your Journey: Expert Coaching and Wellness Blog',
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        BlogSidebar,
        ContactUs
    },
    mixins: [formateDateMixin],
    mounted() {
        this.currentPage = parseInt(this.$route.params.page) || 1
        this.$store.dispatch("resetBlogList");
        this.$store.dispatch("getBlogList");
        this.$store.dispatch("getLastNineBlogList");
        this.$store.dispatch("getCategoryList");
        postscribe(
            "#blog",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
        );
        postscribe(
            "#blog",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
        );
    },
    data() {
		return {
			blogcount: 0,
            currentPage: 1,
            contentPerPage: 5,
            numPages: 0,
            postArray: [],
            shownArray: []
		}
	},
    methods: {
        trimBlogList() {
            if (this.postArray.length > 0) {
                let start = this.contentPerPage * (this.currentPage - 1)
                let end = this.currentPage * this.contentPerPage
                if (start < this.postArray.length) {
                this.shownArray = this.postArray.slice(start, end)
                } else {
                    this.shownArray = this.postArray.slice(0, this.contentPerPage)
                }
            } else {
                this.shownArray = []
            }
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your request. Keep an eye on your inbox; we'll be in touch shortly to schedule our call. Thank you for choosing Ingomu.",
					type: "success",
				});
			}
		},
        imageLink(url) {
            console.log(url)
            console.log(unescape(url.substring(0, url.indexOf("&token="))))
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        }
    },
    computed: {
        ...mapGetters(["blogList", "blogItem", "blogShortList", "catList"]),
    },
    watch: {
        $route(to, from) {
            this.currentPage = parseInt(to.params.page) || 1
            this.trimBlogList()
        },
        blogList(newValue) {
            if (newValue != null) {
                this.numPages = Math.ceil(newValue.length / this.contentPerPage)
                this.blogcount = newValue.length
                this.postArray = newValue
                this.trimBlogList()
            } else {
                this.postArray = []
                this.shownArray = []
                this.numPages = 0
            }
        }
    }
}
</script>
<style scoped>
.blog-page-bg {
    background: linear-gradient(45deg, #46c346, #3b5998);
}

.feature-blog-one .post-meta, .feature-blog-one .post-meta .image-meta {
    border-radius: 25px !important;
}

.blog-page-bg {
    background: #fff;
}

.pt-50 {
    padding-top: 50px;
}
</style>
